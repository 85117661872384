import swissIcon from "./img/swissIcon.png";
import "./App.css";
import { TaxForm } from "./component/TaxForm";
import { Alert } from "@mui/material";




export default function App() {

  return (

    <div className="App">
      <header className="App-header">
        <img src={swissIcon} className="App-logo" alt="logo" />
        <h4>Outil de simulation d'impôt sur le revenu pour les contribuables suisses</h4>
      </header>
      <div className="App-content">
        <Alert severity="info" className="App-disclaimer">
            Ce service n'est délivré qu'à titre informatif.
            Il ne permet d'obtenir qu'une estimation indicative de l'impôt sur le revenu. D'autres déductions peuvent influencer le montant final.
            Pour une estimation plus précise, rendez-vous sur le <a href="https://www.estv.admin.ch/estv/fr/accueil/afc/statistiques-fiscales/calculer-vos-impots.html" target="_blank" rel="noreferrer">simulateur fiscal officiel de la Confédération Suisse</a>
        </Alert>
        <TaxForm />
      </div>
    </div>
  );
}