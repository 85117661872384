import {
  Alert,
  Autocomplete,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Slider,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { SwissLocation } from "../model/SwissLocation";
import "./TaxForm.css";

export function TaxForm() {
  const urlLocation = process.env.REACT_APP_LOCATION_FUNCTION_URL ?? "";
  const urlTax = process.env.REACT_APP_TAX_FUNCTION_URL ?? "";

  const defaultFormData: any = {
    locationId: undefined,
    relationship: undefined,
    age: undefined,
    nbChildrens: undefined,
    revenue: undefined,
    fortune: undefined,
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [grossRevenue, setGrossRevenue] = useState(0);

  useEffect(() => {
    if (isFormIncomplete()) {
      setShowError(false);
    }
  }, [formData]);

  const onChangeField = (field: string, value: any) => {
    setFormData((prevState: any) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const updateGrossRevenue = (value: any) => {
    onChangeField("revenue", value - savedAmount);
  };

  const [showResult, setShowResult] = useState(false);
  const [showError, setShowError] = useState(false);
  const [taxAmount, setTaxAmount] = useState(0);
  const [savedAmount, setSavedAmount] = useState(0);

  useEffect(() => {
    updateGrossRevenue(grossRevenue);
  }, [savedAmount, grossRevenue]);

  const [locationOptions, setLocationOptions] = useState<SwissLocation[]>([]);
  const [open, setOpen] = useState(false);
  const loading = open && locationOptions.length === 0;

  const marks = [
    {
      value: 0,
      label: "0 CHF",
    },
    {
      value: 3528,
      label: "3528 CHF",
    },
    {
      value: 7056,
      label: "7056 CHF",
    },
  ];

  const valueLabelFormat = (value: number) => {
    return `${value} CHF`;
  };

  const renderError = () => {
    return (
      <Alert severity="error" className="App-error">
        Tous les champs sont obligatoires
      </Alert>
    );
  };

  const fetchSwissLocations = (event: any, value: string, reason: any) => {
    if (value) {
      axios
        .get(urlLocation, { params: { search: value } })
        .then((response) => {
          setLocationOptions(response.data);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const formatAmountCHF = (amount: number) => {
    const formattedAmount = amount.toLocaleString("de-CH", {
      maximumFractionDigits: 2,
    });
    return `${formattedAmount} CHF`;
  };

  const renderResult = () => {
    window.location.hash = "#result";
    const taxAmountMonthly = taxAmount / 12;

    return (
      <FormGroup className="CustomFormGroup">
        <Alert severity="success" variant="outlined" sx={{ fontSize: 20 }}>
          Votre montant d'impôts annuel est estimé à{" "}
          <b>{formatAmountCHF(taxAmount)}</b> , soit environ{" "}
          <b>{formatAmountCHF(taxAmountMonthly)}</b> par mois.
        </Alert>

      </FormGroup>
    );
  };

  const handle3aResult = (value: number | number[]) => {
    setSavedAmount(typeof value === "number" ? value : value[0]);
  };


  const isFormIncomplete = () => {
    return Object.keys(formData).some(
      (key) => formData[key] === undefined || formData[key] === undefined
    );
  };

  const computeTax = () => {
    if (isFormIncomplete()) {
      setShowError(true);
    } else {
      axios
        .get(urlTax, { params: formData })
        .then((response) => {
          setTaxAmount(response.data);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          handle3aResult(7056);
          setShowResult(true);
        });
    }
  };

  return (
    <div className="FormContainer">
      <Container>
        {showError ? renderError() : null}
        <FormGroup className="CustomFormGroup">
          <FormControl className="FormElement" required>
            <FormLabel id="location-field">Lieu de domicile</FormLabel>
            <Autocomplete
              disablePortal
              id="location-search"
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              loading={loading}
              onInputChange={fetchSwissLocations}
              isOptionEqualToValue={(option, value) =>
                option.TaxLocationID === value.TaxLocationID
              }
              options={locationOptions}
              getOptionKey={(option) => option.TaxLocationID}
              getOptionLabel={(option) =>
                `${option.ZipCode}, ${option.City}, ${option.Canton}`
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="Lieu de domicile" />
              )}
              onChange={(e, option) =>
                onChangeField("locationId", option?.TaxLocationID)
              }
            />
          </FormControl>
        </FormGroup>

        <FormGroup className="CustomFormGroup" row>
          <FormControl fullWidth className="FormElement" required>
            <FormLabel id="status-field">Etat civil</FormLabel>
            <RadioGroup
              row
              onChange={(e) => onChangeField("relationship", e.target.value)}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Célibataire"
              ></FormControlLabel>
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Marié"
              ></FormControlLabel>
              <FormControlLabel
                value="3"
                control={<Radio />}
                label="Concubinage"
              ></FormControlLabel>
              <FormControlLabel
                value="4"
                control={<Radio />}
                label="Partenariat enregistré"
              ></FormControlLabel>
            </RadioGroup>
          </FormControl>
        </FormGroup>

        <FormGroup className="CustomFormGroup">
          <FormControl className="FormElement" required>
            <FormLabel id="age-field">Age</FormLabel>
            <TextField
              type="number"
              placeholder="Age"
              onChange={(e) => onChangeField("age", e.target.value)}
            />
          </FormControl>
        </FormGroup>
        <FormGroup className="CustomFormGroup">
          <FormControl className="FormElement" required>
            <FormLabel id="childs-field">Nombre d'enfants à charge</FormLabel>
            <TextField
              type="number"
              placeholder="Nombre d'enfants"
              onChange={(e) => onChangeField("nbChildrens", e.target.value)}
            />
          </FormControl>
        </FormGroup>

        <FormGroup className="CustomFormGroup">
          <FormControl className="FormElement" required>
            <FormLabel id="salary-field">Revenu brut annuel</FormLabel>
            <TextField
              type="number"
              placeholder="Revenu brut annuel"
              onChange={(e) => setGrossRevenue(Number(e.target.value))}
            />
          </FormControl>
        </FormGroup>

        <FormGroup className="CustomFormGroup">
          <FormControl className="FormElement" required>
            <FormLabel id="fortune-field">Fortune nette</FormLabel>
            <TextField
              type="number"
              placeholder="Fortune nette"
              onChange={(e) => {
                onChangeField("fortune", e.target.value);
              }}
            />
          </FormControl>
        </FormGroup>

        <FormGroup className="CustomFormGroup">
          <FormControl className="FormElement">
            <FormLabel className="FormSubElement" id="location-field">
              Investissement annuel prévu dans un compte 3ème pilier ( <b>déduction fiscale jusqu'à 7056 CHF</b>)
            </FormLabel>
            <Slider
              className="FormSubElement"
              aria-label="Restricted values"
              defaultValue={7056}
              valueLabelFormat={valueLabelFormat}
              getAriaValueText={formatAmountCHF}
              step={100}
              valueLabelDisplay="on"
              marks={marks}
              min={0}
              max={7056}
              color="warning"
              onChange={(event, value) => handle3aResult(value)}
            />
          </FormControl>
        </FormGroup>

        <FormGroup className="CustomFormGroup">
          <Button variant="contained" onClick={computeTax}>
            Calculer
          </Button>
        </FormGroup>

        <FormGroup className="CustomFormGroup">
          {showResult ? renderResult() : null}
        </FormGroup>
      </Container>
    </div>
  );
}
